'use client';

import type { ReactNode } from 'react';
import { YStack, XStack, Image } from '@cxnpl/ui';
import { Banner } from 'app/features/help/Banner';
import branding from '@cxnpl/ui/brand';
import { useIsEmbeddedModeEnabled } from 'app/services/embeddedMode';

export default function PublicPageLayout({ children }: { children: ReactNode }) {
  const isEmbedded = useIsEmbeddedModeEnabled();

  return (
    <YStack flexGrow={1}>
      <XStack
        alignItems="center"
        backgroundColor="$background/surface"
        justifyContent="space-between"
        paddingHorizontal="$space.lg"
        paddingVertical="$space.sm"
        $tablet={{
          paddingHorizontal: '$space.3xl',
        }}
      >
        <XStack
          gap={8}
          alignItems="center"
          flexDirection="column"
          // For smaller devices than mobile, use column flexDirection
          $mobile={{
            gap: '$space.2xl',
            flexDirection: 'row',
          }}
        >
          <Image
            src={branding.assets.logo.src}
            width={branding.assets.logo.width}
            height={branding.assets.logo.height}
            alt=""
          />
        </XStack>
      </XStack>
      <YStack flexGrow={1}>
        <Image
          src={branding.assets.background.src}
          style={{ objectFit: 'cover' }}
          alt=""
          webProps={{ fill: true, priority: true }}
        />
        <Banner bannerId={isEmbedded ? 'landing' : 'weblogin'} />
        <YStack
          flexGrow={1}
          alignItems="center"
          justifyContent="center"
          backgroundColor="$background/surface"
          $tablet={{
            backgroundColor: 'transparent',
            padding: '$space.8xl',
          }}
        >
          {children}
        </YStack>
      </YStack>
    </YStack>
  );
}
