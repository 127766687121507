import { useGetBanner } from '@cxnpl/api/config';
import { Alert } from '@cxnpl/ui';

export const Banner = ({ bannerId }: { bannerId: string }) => {
  // @ts-expect-error -- Query param override to prevent CORS block https://stackoverflow.com/a/69611094
  const { data, isLoading } = useGetBanner({ preventAdBlock: true, bannerId });

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- react-query is wrong here, the "data" might not be loaded and it also could be undefined
  if (isLoading || !data?.active) {
    return null;
  }

  return (
    <Alert severity="info" multiline>
      {data.message}
    </Alert>
  );
};
